var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pl-6 pr-6"},[_c('v-row',{staticClass:"align-center justify-center",attrs:{"no-gutters":""}},[_c('v-img',{staticClass:"mx-auto",staticStyle:{"max-width":"154px"},attrs:{"width":"154","src":require("@/assets/images/logo/noodzly-logo.png")}})],1),_c('v-row',{staticClass:"align-center justify-center mt-4"},[_c('h1',[_vm._v(_vm._s(_vm.$t('pages.user.onboard.title')))])]),_c('v-row',{staticClass:"pr-2 pl-2 mt-4"},[_c('h3',[_vm._v(_vm._s(_vm.$t('pages.user.onboard.gender')))])]),_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.genders),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"4"}},[_c('div',{class:{
                       'select-gender': true,
                       'pa-2': true,
                       active: item.icon === _vm.model.gender,
                   },on:{"click":function($event){_vm.model.gender = item.icon}}},[_c('app-icon',{staticClass:"gender-icon",attrs:{"icon":item.icon,"size":"16"}}),_c('p',{staticClass:"mb-0 text-caption"},[_vm._v(_vm._s(item.text))])],1)])}),1),_c('v-row',{staticClass:"pr-2 pl-2 mt-4"},[_c('h3',[_vm._v(_vm._s(_vm.$t('pages.user.onboard.interested_gender')))])]),_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.genders),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"4"}},[_c('div',{class:{
                       'select-gender': true,
                       'pa-2': true,
                       active: _vm.user_filters.activeGenders && _vm.user_filters.activeGenders.includes(item.icon),
                   },on:{"click":function($event){return _vm.toggleGender(item.icon)}}},[_c('app-icon',{staticClass:"gender-icon",attrs:{"icon":item.icon,"size":"16"}}),_c('p',{staticClass:"mb-0 text-caption"},[_vm._v(_vm._s(item.text))])],1)])}),1),_c('v-row',{staticClass:"mt-4 pr-2 pl-2"},[_c('h3',[_vm._v(_vm._s(_vm.$t('pages.user.onboard.age')))])]),_c('v-row',{staticClass:"pr-2 pl-2 mt-1",attrs:{"align":"baseline"}},[_vm._v(" 18 "),_c('v-slider',{class:{ 'cursor-slider': true },attrs:{"thumb-label":"","min":"18","max":"100"},model:{value:(_vm.model.age),callback:function ($$v) {_vm.$set(_vm.model, "age", $$v)},expression:"model.age"}}),_vm._v(" 100 ")],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"center"}},[_c('gradient-button',{attrs:{"block":"","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('pages.user.onboard.save'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }