<template>
     <v-container class="pl-6 pr-6">
            <v-row class="align-center justify-center" no-gutters>
                <v-img
                    class="mx-auto"
                    width="154"
                    style="max-width: 154px"
                    src="@/assets/images/logo/noodzly-logo.png"
                />
            </v-row>

            <v-row class="align-center justify-center mt-4">
                <h1>{{ $t('pages.user.onboard.title') }}</h1>
            </v-row>
            <v-row class="pr-2 pl-2 mt-4">
                <h3>{{ $t('pages.user.onboard.gender') }}</h3>
            </v-row>
            <v-row justify="center">
                <v-col cols="4" v-for="(item, i) in genders" :key="i">
                    <div
                        :class="{
                            'select-gender': true,
                            'pa-2': true,
                            active: item.icon === model.gender,
                        }"
                        @click="model.gender = item.icon"
                    >
                        <app-icon
                            :icon="item.icon"
                            class="gender-icon"
                            size="16"
                        />
                        <p class="mb-0 text-caption">{{ item.text }}</p>
                    </div>
                </v-col>
            </v-row>

            <v-row class="pr-2 pl-2 mt-4">
                <h3>{{ $t('pages.user.onboard.interested_gender') }}</h3>
            </v-row>
            <v-row justify="center">
                <v-col cols="4" v-for="(item, i) in genders" :key="i">
                    <div
                        :class="{
                            'select-gender': true,
                            'pa-2': true,
                            active: user_filters.activeGenders && user_filters.activeGenders.includes(item.icon),
                        }"
                        @click="toggleGender(item.icon)"
                    >
                        <app-icon
                            :icon="item.icon"
                            class="gender-icon"
                            size="16"
                        />
                        <p class="mb-0 text-caption">{{ item.text }}</p>
                    </div>
                </v-col>
            </v-row>

            <v-row class="mt-4 pr-2 pl-2">
                <h3>{{ $t('pages.user.onboard.age') }}</h3>
            </v-row>
            <v-row align="baseline" class="pr-2 pl-2 mt-1">
                18
                <v-slider
                    v-model="model.age"
                    thumb-label
                    min="18"
                    max="100"
                    :class="{ 'cursor-slider': true }"
                ></v-slider>
                100
            </v-row>

            <v-row align="center" justify="center" class="mt-6">
                <gradient-button
                    block
                    @click="save"
                    :loading="loading"
                >
                    {{ $t('pages.user.onboard.save') }}
                </gradient-button>
            </v-row>
        </v-container>
</template>

<script>
import GradientButton from "@/components/app/button/GradientButton";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
    name: "UserOnboarding",

    components: {
        GradientButton,
    },

    data() {
        return {
            model: {
                age: 18,
                gender: "male",
            },
            loading: false,
            user_filters: () => {
                return {};
            },
        }
    },

    computed: {
        ...mapState({
            filters: (state) => state.story.filters,
        }),
        genders() {
            return [
                { text: this.$t("pages.profile.info.male"), icon: "male" },
                { text: this.$t("pages.profile.info.female"), icon: "female" },
                {
                    text: this.$t("pages.profile.info.transgender"),
                    icon: "transgender",
                },
            ];
        },
    },

    methods: {
        ...mapActions([
            "setProfileInfo", "getCurrentUserData", "getOwnMainUserData"
        ]),
        ...mapMutations(["setFilters"]),

        async save() {
            this.loading = true;
            await this.setProfileInfo({ model: this.model }).then(async () => {
                this.getCurrentUserData().then((res) => {
                    this.getOwnMainUserData(res.data);
                    this.setFilters(this.user_filters);
                    localStorage.setItem("home_filters", JSON.stringify(this.user_filters));
                    this.$router.push({name: 'auth.home'});
                    this.loading = false;
                });
            });
        },

        toggleGender(icon) {
            const index = this.user_filters.activeGenders.indexOf(icon);
            if (index === -1) {
                this.user_filters.activeGenders.push(icon);
            } else {
                this.user_filters.activeGenders.splice(index, 1);
            }
        },
    },
    mounted() {
        this.user_filters = this.filters;
    },
}
</script>

<style lang="scss" scoped>
@import "@/sass/modules/_variables";

.select-gender.active {
    box-shadow: 0px 2px 17px 0px #fe6c11;
    background: $accent-background;
    border: 1px solid #fe4811;

    & .gender-icon {
        color: white !important;
    }
}

.select-gender {
    border: 1px solid #ededed;
    height: 60px;
    border-radius: 8px;
    text-align: center;
    transition: 0.4s;
    cursor: pointer;

    & .gender-icon {
        color: #9e9ca5 !important;
    }
}
</style>